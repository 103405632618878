<h1 mat-dialog-title>
    {{ (subgroup ? 'ACTION.SUBGROUP-BY-EXPENSE-TYPE.UPDATE' : 'ACTION.SUBGROUP-BY-EXPENSE-TYPE.ADD') | translate }}
</h1>
<form [formGroup]="form"
      autocomplete="off">
    <mat-dialog-content class="item-dialog">
        <!-- description -->
        <mat-form-field class="col-12">
            <mat-label>Descrição</mat-label>
            <input matInput
                   formControlName="description"
                   required>
        </mat-form-field>

        <!-- group -->
        <mat-form-field class="col-12">
            <mat-label>{{ 'PROPERTY.ITEM.GROUP' | translate }}</mat-label>
            <mat-select #groupSelect
                        formControlName="group">
                <mat-option *ngFor="let option of data?.partGroups"
                            [value]="option">
                    {{option.code}} - {{ option.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="close()">
            {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label=save
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid">
            {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
    </mat-dialog-actions>
</form>