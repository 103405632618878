import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CashierEventType } from '../../../interface/cashier-event';
import { ExpenseType } from '../../../interface/expense-type';
import { CashierEventTypeService } from '../../../service/cashier-event-type.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';

@Component({
  selector: 'app-cashier-event-type-edit',
  templateUrl: './cashier-event-type-edit.component.html',
  styleUrl: './cashier-event-type-edit.component.scss'
})
export class CashierEventTypeEditComponent implements OnInit, OnDestroy {

  form: FormGroup = new FormBuilder().group({
    _id: '',
    description: ['', Validators.required],
    expenseType: [''],
    typeEvent: [true, Validators.required],
    dre: [true, Validators.required],
    financial: [true, Validators.required]
  });

  expenseTypes: ExpenseType[] = [];
  sub: Subscription;
  event: CashierEventType;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {
      expenses: ExpenseType[],
      event?: CashierEventType
    },
    private _layout: LayoutService,
    private _cetService: CashierEventTypeService,
    private _dialogRef: MatDialogRef<CashierEventTypeEditComponent>,
    private _snackbar: SnackbarService
  ) { 
    this.sub = this.form.valueChanges.subscribe(formValue => {
      if (formValue.typeEvent){
        this.form.get('expenseType').enable({ emitEvent: false });
        this.form.get('expenseType').setValidators(Validators.required);
      } else {
        this.form.get('expenseType').disable({ emitEvent: false });
        this.form.get('expenseType').clearValidators();
      }
      this.form.get('expenseType').updateValueAndValidity({ emitEvent: false});
    })
  }

  ngOnInit() {
    this.expenseTypes = this.data.expenses;
    if(this.data.event){
      const apiObj = this.data.event.toAPI();
      this.event = this.data.event;
      this.form.patchValue(apiObj);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public async save(){
    const formValue = this.form.getRawValue();
    try {
      this._layout.loader = true;
      const event = new CashierEventType(formValue);
      if(event.id){
        await this._cetService.update(event);
      } else {
        await this._cetService.create(event);
      }
      this._snackbar.success(formValue._id ? 'CASHIER_EVENT_TYPE.UPDATED' : 'CASHIER_EVENT_TYPE.ADDED');
      this._dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this._snackbar.error(formValue._id ? 'CASHIER_EVENT_TYPE.NOT_UPDATED' : 'CASHIER_EVENT_TYPE.NOT_ADDED');
    } finally {
      this._layout.loader = false;
    }
  }

}
