import { Component, Inject, OnInit } from '@angular/core';
import { BANK_CODES } from '../../../shared/lists/bank-codes';
import { AutoCompleteUtilities } from '../../../class/autocomplete-utilities';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bank } from '../../../interface/bank';
import { BankService } from '../../../service/bank.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { LayoutService } from '../../../service/layout.service';

@Component({
  selector: 'app-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrl: './bank-edit.component.scss'
})
export class BankEditComponent implements OnInit {

  BANK_CODES = BANK_CODES;
  AutoUtilities = AutoCompleteUtilities;
  selectedBank: string | {
    code: string;
    name: string;
  }

  form = new FormBuilder().group({
    _id: '',
    code: ['', Validators.required],
    name: ['', Validators.required],
    description: [''],
    operation: '',
    agencia: ['', Validators.required],
    account: ['', Validators.required],
    digito: ['', Validators.required],
    saldo: [undefined as number, Validators.required]
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { bank: Bank },
    private _bankService: BankService,
    private _snackbar: SnackbarService,
    private _layout: LayoutService,
    private _dialogRef: MatDialogRef<BankEditComponent>,
  ) { }

  ngOnInit() {
    if (this.data && this.data.bank) {
      this.form.patchValue(this.data.bank.toAPI());  
      this.selectedBank = BANK_CODES.find(b => b.name === this.data.bank.name);
      this.form.get('saldo').disable();
    }
  }


  public setBank() {
    if (this.selectedBank && typeof this.selectedBank !== 'string') {
      this.form.get('name').setValue(this.selectedBank.name);
      this.form.get('code').setValue(this.selectedBank.code);
    } else {
      this.form.get('name').setValue('');
      this.form.get('code').setValue('');
    }
  }

  public async save() {
    const newBank = new Bank(this.form.getRawValue());
    try {
      this._layout.loader = true;
      if(newBank._id){
        await this._bankService.update(newBank)
      } else {
        await this._bankService.create(newBank);
      }
      this._snackbar.success('BANK.'+ (newBank._id ? 'UPDATED' : 'ADDED'));
      this._dialogRef.close(true);
    } catch (error) {
      console.error(error)
      this._snackbar.success('BANK.'+ (newBank._id ? 'NOT_UPDATED' : 'NOT_ADDED'));
    } finally {
      this._layout.loader = false;
    }

  }

}
