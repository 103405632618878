<h1 mat-dialog-title>
    {{ (group ? 'ACTION.GROUP-BY-EXPENSE-TYPE.UPDATE' : 'ACTION.GROUP-BY-EXPENSE-TYPE.ADD') | translate }}
</h1>
<form *ngIf="form" [formGroup]="form"
      autocomplete="off">
    <mat-dialog-content class="item-dialog">

        <!-- description -->
        <mat-form-field class="col-8">
            <input matInput
                   formControlName="description"
                   placeholder="Descrição"
                   required>
        </mat-form-field>

        <mat-form-field class="col-4">
            <mat-label>Categoria</mat-label>
            <mat-select [value]="GROUP_KIND.expense" formControlName="groupKind">
                <mat-option [value]="GROUP_KIND.expense">Tipo de despesa</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="close()">
            {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label=save
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid">
            {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
    </mat-dialog-actions>
</form>
