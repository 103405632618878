<h1 matDialogTitle>
    {{ event?.id ? event.description : 'Registrar tipo de evento'}}
</h1>

<mat-dialog-content>
    <form [formGroup]="form"
          autocomplete="off">

        <div class="mat-form-field col-6">
            <mat-label>Tipo de movimentação</mat-label>
            <br>
            <mat-radio-group required
                             formControlName="typeEvent">
                <mat-radio-button [value]="true"
                                  class="mx-2"
                                  color="primary">Pagar</mat-radio-button>
                <mat-radio-button [value]="false"
                                  class="mx-2"
                                  color="primary">Receber</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="mat-form-field col-6">
            <mat-label>Aplicação</mat-label>
            <br>
            <mat-radio-group required
                             formControlName="financial">
                <mat-radio-button [value]="true"
                                  class="mx-2"
                                  color="primary">Caixa</mat-radio-button>
                <mat-radio-button [value]="false"
                                  class="mx-2"
                                  color="primary">Banco</mat-radio-button>
            </mat-radio-group>
        </div>

        <mat-form-field class="col-12">
            <mat-label>Descrição</mat-label>
            <input matInput
                   required
                   maxlength="60"
                   formControlName="description">
        </mat-form-field>

        <mat-form-field class="col-12">
            <mat-label>Tipo de despesa</mat-label>
            <mat-select formControlName="expenseType">
                <mat-option *ngFor="let expense of expenseTypes"
                            [value]="expense._id">
                    {{ expense.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="mat-form-field col-12">
            <mat-checkbox formControlName="dre"
                          color="primary">Aparece no DRE</mat-checkbox>
        </div>

    </form>

</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button
            color="warn"
            [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid || form.pristine"
            (singleClick)="save()">Salvar</button>
</mat-dialog-actions>