import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';
import { AutoCompleteUtilities } from '../../../class/autocomplete-utilities';
import { Part } from '../../../interface/part';
import { Labor } from '../../../interface/labor';
import { RoType } from '../../../interface/ro-type';
import { MatTableDataSource } from '@angular/material/table';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PACKET_ITEM_TYPE, Packet, PacketItem } from '../../../interface/carte';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-packet-edit',
  templateUrl: './packet-edit.component.html',
  styleUrl: './packet-edit.component.scss'
})
export class PacketEditComponent extends UnsubscribeAll implements OnInit, OnDestroy {

  @ViewChild('addItemInput', { static: true }) addItemInput: ElementRef<HTMLInputElement>;

  AutoUtilities = AutoCompleteUtilities;
  allItems: (Part | Labor)[] = [];
  roTypes: RoType[] = [];
  packetItemColumns = [
    "default",
    "idLaborOrPart",
    "MOCode",
    "qty",
    "osType",
    "active",
    "factory",
    "actions"
  ];

  itemsTable = new MatTableDataSource([]);

  form = new FormBuilder().group({
    _id: [""],
    name: ["", Validators.required],
    number: [""],
    laborDefault: [""],
    factoryCode: [""],
    code: [""],
    // qtyInstallments: [undefined as number, Validators.required],
    // standardInstallment: [0, Validators.required],
    // interestAfter: [0, Validators.required],
    // fees: [0, Validators.required],
    value: [undefined],
    items: [[] as PacketItem[]]
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: {
      roTypes: RoType[],
      packet?: Packet,
      parts?: Part[],
      labors?: Labor[]
    }
  ) {
    super();
    this.subs.push(this.form.get('items').valueChanges.subscribe((arr: Array<PacketItem>) => {
     
      if(arr){
        this.itemsTable.data = arr;
        this.form.get('laborDefault').setValidators(this.included(arr.slice().map(i => i.idLaborOrPart)));
        this.calculateValue();
      }
      
      
    }));
    if (data) {
      this.roTypes = data.roTypes;
      this.allItems = data.parts || [];
      this.allItems = this.allItems.concat(data.labors || []);
      if (data.packet) {
        this.form.patchValue(data.packet)
      }
    }
  }

  ngOnInit() {
    console.log({data: this.data, dataPacket: this.data.packet})
    if (this.data && this.data.packet) {
      this.form.patchValue(this.data.packet);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  private included(validValues: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (validValues.some(value => value === control.value)) {
        return null
      }
      return { not_included: true }
    };
  }

  addItem(event: MatAutocompleteSelectedEvent) {
    const item: Part | Labor = event.option.value;
    const items: PacketItem[] = this.form.get('items').value || [];
    const addedIndex = items.findIndex(i => i.idLaborOrPart === item.id);
    if (addedIndex !== -1) {
      items[addedIndex].qty += 1;
      this.form.get('items').setValue(items);
      this.addItemInput.nativeElement.value = "";
      return
    }
    const isPart = this.data.parts.some(part => part.id === item.id);
    const pack: PacketItem = {
      idLaborOrPart: item.id,
      factory: 1,
      active: 1,
      MOCode: item.code,
      qty: 1,
      osType: this.roTypes.length === 1 ? this.roTypes[0].id : "",
      type: isPart ? PACKET_ITEM_TYPE.part : PACKET_ITEM_TYPE.labor
    }
    items.push(pack);
    if(items.length === 1){
      this.form.get('laborDefault').setValue(pack.idLaborOrPart);
    }
    this.addItemInput.nativeElement.value = "";
    this.form.get('items').setValue(items);
  }

  deleteItem(index: number) {
    const items:PacketItem[] = this.form.get('items').value || [];
    const deleted = items.splice(index, 1);
    if(deleted[0].idLaborOrPart === this.form.get('laborDefault').value){
      if(items.length)
      this.form.get('laborDefault').setValue(items.length ? items[0].idLaborOrPart : "");
    }
    this.form.get('items').setValue(items);
  }

  onChangeFactory(item: PacketItem, value: boolean) {
    if (value) {
      item.factory = 1;
      item.active = 1;
      this.calculateValue();
    } else {
      item.factory = 2
    }
  }

  onChangeActive(item: PacketItem, value: boolean) {
    item.active = value ? 1 : 2;
    this.calculateValue();
  }

  setLaborDefault(item: PacketItem){
    const laborDefault = this.form.get('laborDefault');
    if(item.idLaborOrPart === laborDefault.value){
      laborDefault.setValue("");
    } else {
      laborDefault.setValue(item.idLaborOrPart);
    }
    laborDefault.updateValueAndValidity();
  }

  public calculateValue(){
    const items: PacketItem[] = this.form.get('items').value || [];
    let sum  = 0;
    for (const packItem of items) {
      if(packItem.active === 1){
        const partOrLabor = this.allItems.find(i => i.id === packItem.idLaborOrPart);
        sum += (partOrLabor.saleValue || 0) * (packItem.qty || 0);
      }
    }
    this.form.get('value').setValue(sum);
  }

  drop(event: CdkDragDrop<string>) {
    // other code on drop event
    // 
    const previousIndex = this.itemsTable.data.findIndex((d) => d === event.item.data);

    moveItemInArray(this.itemsTable.data, previousIndex, event.currentIndex);
    this.itemsTable.data = this.itemsTable.data;
  }


}
