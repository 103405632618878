<h1 matDialogTitle>{{ form.value.id ? 'Atualizar' : 'Registrar' }} status adicional da OS </h1>
<mat-dialog-content>

    <form [formGroup]="form">
        <mat-form-field class="col-8">
            <mat-label>Descrição</mat-label>
            <input matInput
                   autocomplete="null"
                   formControlName="description">
        </mat-form-field>

        <mat-form-field class="col-4">
            <mat-label>Cor</mat-label>
            <input matInput
                   type="color"
                   formControlName="color">
        </mat-form-field>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button
            color="warn"
            [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid"
            [mat-dialog-close]="form.value">Salvar</button>
</mat-dialog-actions>