import { Component, Inject, Optional } from '@angular/core';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { QUESTION_TYPE, Question } from '../../../interface/checklist-model';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-add-checklist-question',
  templateUrl: './add-checklist-question.component.html',
  styleUrl: './add-checklist-question.component.scss'
})
export class AddChecklistQuestionComponent extends UnsubscribeAll {

  QUESTION_TYPE = QUESTION_TYPE;
  QUESTION_TYPES: QUESTION_TYPE[] = [];
  CHECKLIST_QUESTION_TYPES = [
    QUESTION_TYPE.BOOLEAN,
    QUESTION_TYPE.LIST,
    QUESTION_TYPE.DAMAGE_LEVEL,
    QUESTION_TYPE.NUMERIC_NIVEL,
    QUESTION_TYPE.TEXT
  ]
  
  SURVEY_QUESTION_TYPES = [
    QUESTION_TYPE.BOOLEAN,
    QUESTION_TYPE.LIST,
    QUESTION_TYPE.NUMERIC_NIVEL,
    QUESTION_TYPE.TEXT,
    QUESTION_TYPE.STAR
  ]

  currentResp: string = "";
  groups: string[] = [];

  list = new MatTableDataSource<string>([]);

  form: FormGroup = new FormBuilder().group({
    id: "",
    questionType: QUESTION_TYPE.BOOLEAN,
    booleanQuestionName: ["", Validators.required],
    group: [""],
    // listQuestionName: "",
    list: [[]],
    min: [0],
    max: [10, Validators.min(1)],
    default: [0],

    step: [1],
    rangeMinRed: [0],
    rangeMaxRed: [4],
    // rangeMinYellow: [40],
    rangeMaxYellow: [5],
    // rangeMinGreen: [50],
    // rangeMaxGreen: [100],

  });

  private _options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    noSwitching: true,
    draggableRange: true,
    enforceStep: false,
    enforceRange: false,
    showTicks: true,
    showOuterSelectionBars: true,
    showSelectionBar: true,
    getSelectionBarColor: (min, max) => {
      if (!max) {
        if (min < this.form.value.rangeMaxRed) {
          return 'red';
        }
        if (min < this.form.value.rangeMaxYellow) {
          return 'yellow';
        }
        return 'green';
      }
      return "black";
    }
  };
  public get options(): Options {
    return this._options;
  }
  public set options(value: Options) {
    this._options = value;
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      useIn: "checklist" | "survey"
      question: Question,
      groups: string[],
      lastQuestion?: Question
    },
    private _dialogref: MatDialogRef<AddChecklistQuestionComponent>
  ) {
    super();

    if(data.useIn === "checklist"){
      this.QUESTION_TYPES = this.CHECKLIST_QUESTION_TYPES;
      this.form.get('group').setValidators(Validators.required);
    } else {
      this.QUESTION_TYPES = this.SURVEY_QUESTION_TYPES;
    }

    this.subs.push(this.form.get('min').valueChanges.subscribe(value => {
      this.changeSliderOptions({ floor: value })
    }));
    this.subs.push(this.form.get('max').valueChanges.subscribe(value => {
      this.changeSliderOptions({ ceil: value });
    }));
    this.subs.push(this.form.get('step').valueChanges.subscribe(value => {
      this.changeSliderOptions({ step: value});
    }));

    if (data) {
      if (data.question) {
        this.form.patchValue(JSON.parse(JSON.stringify(data.question))); // Deep copy
        this.options.floor = data.question.min || 0;
        this.options.ceil = data.question.max || 10;
        this.options.step = data.question.step || 1;
        this.list.data = this.form.get('list').value || [];
      } 
      else if(data.lastQuestion){
        this.form.get('questionType').setValue(data.lastQuestion.questionType);
        // this.form.patchValue(JSON.parse(JSON.stringify(data.lastQuestion))); // Deep copy
        // this.form.get('booleanQuestionName').setValue('', { emitEvent: false });
        // this.options.floor = data.lastQuestion.min || 0;
        // this.options.ceil = data.lastQuestion.max || 10;
        // this.options.step = data.lastQuestion.step || 1;
        // this.list.data = this.form.get('list').value || [];
      }
      if (data.groups) {
        this.groups = data.groups.reverse();
      }
    }
  }

  changeSliderOptions(newOptions: Options = {}){
    this.options = Object.assign({}, this.options, newOptions);
  }

  addRespOption(event?: Event/*KeyboardEvent*//** keyup.enter */) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.currentResp) {
      const txtToAdd = this.currentResp.trim();
      if (txtToAdd.length) {
        const data = this.form.get('list').value || [];
        data.push(txtToAdd);
        this.form.get('list').setValue(data);
        this.list.data = data;
        this.currentResp = "";
      }
    }
  }

  removeRespOption(index: number) {
    const list = this.form.get('list').value;
    list.splice(index, 1);
    this.form.get('list').setValue(list);
    this.list.data = list;
  }

  onPressEnter() {
    if (this.form.get('booleanQuestionName').value) {
      this._dialogref.close(this.form.value);
    }
  }

  ngOnDestroy(): void {
    if (this.currentResp.length) {
      const resp = confirm(`Deseja incluir o item "${this.currentResp}" nas opções de resposta?`);
      if (resp) {
        this.addRespOption();
      }
    }
    this.unsubscribeAll();
  }

}

