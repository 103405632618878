<h1 matDialogTitle>
    {{ form.value._id ? 'Editar': 'Registrar'}} banco
</h1>

<mat-dialog-content>
    <form [formGroup]="form"
          autocomplete="off">

        <mat-form-field class="col-6">
            <mat-label>Nome</mat-label>
            <input matInput
                   #bankNameInput
                   [(ngModel)]="selectedBank"
                   [ngModelOptions]="{ standalone:true }"
                   (blur)="setBank()"
                   [matAutocomplete]="bankNameAutocomplete">
            <mat-autocomplete #bankNameAutocomplete="matAutocomplete"
                              [displayWith]="AutoUtilities.displayName"
                              (optionSelected)="setBank()">
                <mat-option *ngFor="let bank of BANK_CODES | filter : bankNameInput.value: ['code', 'name']"
                            [value]="bank">
                    {{bank.code}} - {{ bank.name }}
                </mat-option>
            </mat-autocomplete>
            <small matSuffix
                   *ngIf="form.value.code">({{ form.value.code }}) &nbsp;</small>
        </mat-form-field>


        <mat-form-field class="col-3">
            <mat-label>Descrição</mat-label>
            <input matInput
                   maxlength="60"
                   formControlName="description">
        </mat-form-field>

        <mat-form-field class="col-3">
            <mat-label>Operação</mat-label>
            <input matInput
                   maxlength="10"
                   formControlName="operation">
        </mat-form-field>

        <mat-form-field class="col-3">
            <mat-label>Agência</mat-label>
            <input matInput
                   required
                   maxlength="8"
                   formControlName="agencia">
        </mat-form-field>

        <mat-form-field class="col-3">
            <mat-label>Conta</mat-label>
            <input matInput
                   required
                   maxlength="9"
                   formControlName="account">
        </mat-form-field>

        <mat-form-field class="col-3">
            <mat-label>Dígito</mat-label>
            <input matInput
                   required
                   maxlength="2"
                   formControlName="digito">
        </mat-form-field>

        <mat-form-field class="col-3">
            <mat-label>Saldo</mat-label>
            <span matPrefix
                  *ngIf="saldoInput.value">R$</span>
            <input matInput
                   required
                   #saldoInput
                   money
                   maxlength="13"
                   formControlName="saldo">
        </mat-form-field>
    </form>

</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button
            color="warn"
            [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid || form.pristine"
            (singleClick)="save()">Salvar</button>
</mat-dialog-actions>
