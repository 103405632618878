import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ControlMessageService } from 'src/app/service/control-message.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { ControlMessage } from 'src/app/interface/control-message';
import { ControlMessageType } from 'src/app/interface/enum-controlMessages-type';
import { EmojiData, EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { LayoutService } from 'src/app/service/layout.service';

@Component({
  selector: 'app-control-message-edit',
  templateUrl: './control-message-edit.component.html',
  styleUrls: ['./control-message-edit.component.scss']
})
export class ControlMessageEditComponent implements OnInit, OnDestroy {

  form: FormGroup;
  subscription: Subscription;
  hours: string[] = Array.from({ length: 24 }, (_, i) => `${i}:00`);
  showEmojiPicker: boolean = false;
  selectedEmoji: EmojiData | undefined;

  // Tipos de mensagem que exibem o seletor de horário
  timeSelectorTypes: ControlMessageType[] = [
    ControlMessageType.Aniversariante,
    ControlMessageType.PosVenda
  ];

  // Definindo opções estáticas para o campo type
  typeOptions: { id: ControlMessageType, label: string }[] = [
    { id: ControlMessageType.Aniversariante, label: 'Aniversariante' },
    { id: ControlMessageType.PosVenda, label: 'Pós-venda' },
    { id: ControlMessageType.AbrirOrcamento, label: 'Abrir orçamento' },
    { id: ControlMessageType.SolicitacaoAtualizacao, label: 'Solicitação de autorização' },
    { id: ControlMessageType.AbrirOS, label: 'Abrir os' },
    { id: ControlMessageType.FecharOS, label: 'Fechar os' },
    { id: ControlMessageType.PesquisaDeSatisfacao, label: 'Pesquisa de Satisfação'}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { event?: ControlMessage } | null,
    private fb: FormBuilder,
    private controlMessageService: ControlMessageService,
    private dialogRef: MatDialogRef<ControlMessageEditComponent>,
    private layoutService: LayoutService,
    private snackbar: SnackbarService
  ) {
    // Inicializa o formulário com os dados da mensagem recebida
    this.form = this.fb.group({
      _id: [data?.event?._id],
      type: [data?.event?.type || '', Validators.required],
      message: [data?.event?.message || '', Validators.required],
      time: [data?.event?.time || ''],
      osDay: [data?.event?.osDay || '']
    });

    // Subscrição para atualizações do formulário
    this.subscription = this.form.valueChanges.subscribe(value => {
      // console.log('Form changes', value);
    });
  }

  ngOnInit() {
    // console.log("aqui no editar", this.data.event)
    if(this.data.event) {
      const objeto = this.data.event
      this.form.patchValue(objeto);
    }
  }

  ngOnDestroy(): void {
    // Limpa a subscrição para evitar vazamentos de memória
    this.subscription.unsubscribe();
  }

  // Salva a mensagem ao fechar o diálogo
  save(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      // Converte emojis para Unicode antes de salvar
      formValue.message = this.toUnicode(formValue.message);
      this.controlMessageService.saveMessage(formValue).then(() => {
        this.controlMessageService.loadMessages(); // Recarrega as mensagens
        this.dialogRef.close(formValue);
      }).catch(error => {
        console.error('Erro ao salvar a mensagem', error);
        this.snackbar.error('Erro ao salvar a mensagem');
      });
    } else {
      this.snackbar.error('Por favor, preencha todos os campos obrigatórios');
    }
  }

  deleteMessage(): void {
    const messageId = this.form.get('_id').value;
    this.controlMessageService.deleteMessage(messageId).then(() => {
      this.dialogRef.close(true);
    }).catch(error => {
      console.error('Erro ao excluir a mensagem', error);
      this.snackbar.error('Erro ao excluir a mensagem');
    });
  }
  // Retorna o rótulo do tipo com base no ID
  getMessageTypeLabel(type: ControlMessageType): string {
    const selectedType = this.typeOptions.find(option => option.id === type);
    return selectedType ? selectedType.label : '';
  }

  // Retorna o título do diálogo com base no tipo selecionado
  getDialogTitle(): string {
    const type = this.form.get('type').value;
    if (type) {
      return this.getMessageTypeLabel(type);
    } else {
      return 'Mensagem WhatsApp';
    }
  }

  // Verifica se deve exibir o seletor de horário
  showTimeSelector(): boolean {
    const type = this.form.get('type').value;
    return this.timeSelectorTypes.includes(type);
  }

  // Verifica se deve exibir o campo de dias após fechamento
  isPosVenda(): boolean {
    const type = this.form.get('type').value;
    return type === ControlMessageType.PosVenda;
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = ! this.showEmojiPicker;
    console.log('showEmojiPicker:', this.showEmojiPicker);
  }

  private toUnicode(value: string): string {
    return value.split('').map(char => '\\u' + char.charCodeAt(0).toString(16).padStart(4, '0')).join('');
  }

  // Adiciona o emoji selecionado à mensagem
  addEmoji($event: any): void {
    console.log($event);
    this.selectedEmoji = $event.emoji;
    // Obtém a mensagem atual
    const currentMessage = this.form.get('message').value;
    // Atualiza a mensagem com o emoji adicionado
    this.form.get('message').setValue(currentMessage + $event.emoji.native);
    this.toggleEmojiPicker();
  }
  
}
