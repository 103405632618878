import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomState } from '../../../interface/custom-state.model';

@Component({
  selector: 'app-custom-state-edit',
  templateUrl: './custom-state-edit.component.html',
  styleUrl: './custom-state-edit.component.scss'
})
export class CustomStateEditComponent {

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
    color: new FormControl('', [Validators.required])
  })

  constructor(@Inject(MAT_DIALOG_DATA) data: {
    state?: CustomState
  }) {
    if (data && data.state) {
      this.form.patchValue(data.state)
    }
  }



}
